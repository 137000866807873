import Vue from 'vue'

export const state = () => ({
  inspectionReport: {
    units: [],
    users: [],
    photos: [],
    appliances: [],
    furniture: [],
    entities: [],
    inspectors: [],
    comments: [],
    report_type: null,
    id: null
  },
  inspectionsConfig: {
    units: [],
    furniture: [],
    appliances: []
  },
  fetchedInspection: null,
  pdf: null
})

export const mutations = {
  setInspectionReport(state, value) {
    state.fetchedInspection = value
    state.inspectionReport = value
  },
  setReportType(state, value) {
    state.inspectionReport.report_type = value
  },
  setConductedAt(state, value) {
    state.inspectionReport.conducted_at = value
  },
  addEntity(state, data) {
    let roleList
    switch (data?.role) {
      case 'LL':
        roleList = 'landlords'
        break
      case 'TN':
        roleList = 'tenants'
        break
      case 'IN':
        roleList = 'inspectors'
        break
      default:
        return
    }
    state.inspectionReport.entities.push(data)
    state.inspectionReport[roleList].push(data)
  },
  deleteEntity(state, payload) {
    let roleList
    switch (payload?.role) {
      case 'LL':
        roleList = 'landlords'
        break
      case 'TN':
        roleList = 'tenants'
        break
      case 'IN':
        roleList = 'inspectors'
        break
      default:
        return
    }

    state.inspectionReport.entities = state.inspectionReport.entities.filter(
      (x) => x.id !== payload.id
    )
    state.inspectionReport[roleList] = state.inspectionReport[roleList].filter(
      (x) => x.id !== payload.id && x.role === payload.role
    )
  },
  removeUnitById(state, unitId) {
    state.inspectionReport.units = state.inspectionReport.units.filter(
      (u) => u.id !== unitId
    )
  },
  addSubject(state, { subject, unitId }) {
    const unitIndex = state.inspectionReport.units.findIndex(
      (u) => u.id === unitId
    )
    state.inspectionReport.units[unitIndex].subjects = [
      ...state.inspectionReport.units[unitIndex].subjects,
      subject
    ]
  },
  addOtherSubject(state, { subject, type }) {
    switch (type) {
      case 'appliances':
        state.inspectionReport.appliances = [
          ...state.inspectionReport.appliances,
          subject
        ]
        break
      case 'furniture':
        state.inspectionReport.furniture = [
          ...state.inspectionReport.furniture,
          subject
        ]
        break
      default:
        break
    }
  },
  removeOtherSubject(state, { subjId, subjType }) {
    switch (subjType) {
      case 'appliances':
        state.inspectionReport.appliances =
          state.inspectionReport.appliances.filter((a) => a.id !== subjId)
        break
      case 'furniture':
        state.inspectionReport.furniture =
          state.inspectionReport.furniture.filter((a) => a.id !== subjId)
        break
      default:
        break
    }
  },
  removeSubject(state, { subjId, unitId }) {
    const unitIndex = state.inspectionReport.units.findIndex(
      (u) => u.id === unitId
    )
    state.inspectionReport.units[unitIndex].subjects =
      state.inspectionReport.units[unitIndex].subjects.filter(
        (s) => s.id !== subjId
      )
  },
  setUnitsList(state, value) {
    state.inspectionsConfig.units = value
  },
  addUnitItem(state, newUnit) {
    state.inspectionReport.units = [...state.inspectionReport.units, newUnit]
  },
  setInspectionsConfig(state, payload) {
    state.inspectionsConfig = payload
  },
  setSubjectConditionAssessment(state, payload) {
    const { unitId, subject } = payload
    const unit = state.inspectionReport.units.find((u) => u.id === unitId)
    unit.subjects = [
      ...unit.subjects.filter((s) => s.id !== subject.id),
      subject
    ]
    state.inspectionReport.units = [
      ...state.inspectionReport.units.filter((u) => u.id !== unitId),
      unit
    ]
  },
  setAppliance(state, { data, type }) {
    switch (type) {
      case 'appliances':
        state.inspectionReport.appliances = [
          ...state.inspectionReport.appliances.filter((a) => a.id !== data.id),
          data
        ]
        break
      case 'furniture':
        state.inspectionReport.furniture = [
          ...state.inspectionReport.furniture.filter((a) => a.id !== data.id),
          data
        ]
        break
      default:
        break
    }
  },
  setPhotos(state, payload) {
    Vue.set(
      state.inspectionReport.photos,
      state.inspectionReport.photos.length,
      payload
    )
  },
  setUnitPhotos(state, photo) {
    const idx = state.inspectionReport.photos.findIndex(
      (p) => p.id === photo.id
    )
    Vue.set(state.inspectionReport.photos, idx, photo)
  },
  deletePhotoMutation(state, photoId) {
    const idx = state.inspectionReport.photos.findIndex((p) => p.id === photoId)
    Vue.delete(state.inspectionReport.photos, idx)
  },

  // comments
  setComment(state, comment) {
    Vue.set(
      state.inspectionReport.comments,
      state.inspectionReport.comments.length,
      comment
    )
  },
  deleteComment(state, commentId) {
    const comment = state.inspectionReport.comments.findIndex(
      (c) => c.id === commentId
    )
    Vue.delete(state.inspectionReport.comments, comment)
  },
  setPdf(state, data) {
    state.pdf = data
  }
}

export const getters = {
  // validations steps
  stepValid:
    (state, getters) =>
    (stepName, route = stepName) => {
      const validators = {
        'inspection-reports-id-edit': getters.propertyStepValid,
        'inspection-reports-id-edit-parties': getters.partiesStepValid,
        'inspection-reports-id-edit-units': getters.unitStepValid,
        'inspection-reports-id-edit-signing-page': getters.signingStepValid
      }
      return validators[stepName](stepName, route)
    },
  propertyStepValid: (state) => () => {
    return Boolean(state.inspectionReport.document)
  },
  partiesStepValid: (state) => () => {
    return Boolean(state.inspectionReport.users)
  },
  unitStepValid: (state) => () => {
    return true
  },
  signingStepValid: (state) => () => {
    return true
  },
  getInspectionReport: (state) => {
    return state.inspectionReport
  },
  getInspectionReportPhotos: (state) => {
    return state.inspectionReport.photos
  },
  unitList: (state) => {
    return state.inspectionsConfig.units
  },
  units: (state) => {
    return state.inspectionReport.units
  },
  inspectionsConfig: (state) => {
    return state.inspectionsConfig
  },
  fetchedInspection: (state) => {
    return state.fetchedInspection?.units
  },
  getPdf: (state) => {
    return state.pdf
  },
  getRentalContract: (state) => {
    return state.inspectionReport.rental_contract
  }
}

export const actions = {
  createInspectionEntity(context, { id, payload }) {
    return new Promise((resolve, reject) => {
      this.$axios
        .post(`/inspection-reports/${id}/report-entities/`, payload)
        .then((response) => {
          context.commit('addEntity', response.data)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  deleteInspectionEntity(context, { payload }) {
    console.log('DELETING')
    console.log(payload)
    return new Promise((resolve, reject) => {
      this.$axios
        .delete(`/inspection-entities/${payload.id}/`)
        .then((response) => {
          context.commit('deleteEntity', payload)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  sendComment(context, payload) {
    return new Promise((resolve, reject) => {
      this.$axios
        .post(
          `/inspection-reports/${context.state.inspectionReport?.id}/comments/`,
          { comment: payload }
        )
        .then((comment) => {
          context.commit('setComment', comment.data)
          resolve()
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  deleteComment(context, id) {
    return new Promise((resolve, reject) => {
      this.$axios
        .delete(`/inspection-comments/${id}/`)
        .then(() => {
          context.commit('deleteComment', id)
          resolve()
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  createUnit(context, tag) {
    return new Promise((resolve, reject) => {
      this.$axios
        .post(
          `/inspection-reports/${context.state.inspectionReport?.id}/units/`,
          {
            unit_type: tag
          }
        )
        .then((resp) => {
          context.commit('addUnitItem', resp.data)
          resolve(resp.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  removeUnit(context, id) {
    return new Promise((resolve, reject) => {
      this.$axios
        .delete(`/inspection-units/${id}/`)
        .then((response) => {
          context.commit('removeUnitById', id)
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  createSubject(context, payload) {
    return new Promise((resolve, reject) => {
      const { unitId, ...subject } = payload
      this.$axios
        .post(`/inspection-units/${unitId}/subjects/`, subject)
        .then((resp) => {
          context.commit('addSubject', { subject: resp.data, unitId })
          resolve()
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  createOtherSubject(context, { data, type }) {
    return new Promise((resolve, reject) => {
      this.$axios
        .post(
          `/inspection-reports/${context.state.inspectionReport.id}/other-subjects/`,
          data
        )
        .then((resp) => {
          context.commit('addOtherSubject', { subject: resp.data, type })
          resolve()
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  removeSubject(context, { subjId, unitId }) {
    return new Promise((resolve, reject) => {
      this.$axios
        .delete(`/inspection-subjects/${subjId}/`)
        .then(() => {
          context.commit('removeSubject', { subjId, unitId })
          resolve()
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  removeOtherSubject(context, { subjId, subjType }) {
    return new Promise((resolve, reject) => {
      this.$axios
        .delete(`/inspection-subjects/${subjId}/`)
        .then(() => {
          context.commit('removeOtherSubject', { subjId, subjType })
          resolve()
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  fetchInspectionsConfig(context) {
    return new Promise((resolve, reject) => {
      this.$axios
        .get(`/inspections/config/`)
        .then((resp) => {
          const fetchedUnits = resp.data.units.reduce(
            (acc, curr, index) => ({
              ...acc,
              [`${index}`]: {
                id: null,
                tag: curr.tag,
                subjects: curr.subjects.reduce(
                  (acc, curr, index) => ({
                    ...acc,
                    [`${index}`]: {
                      id: null,
                      tag: curr.tag,
                      age: 'UNKN',
                      status: 'GOOD',
                      material: 'UNKN',
                      condition_description: '',
                      general_description: ''
                    }
                  }),
                  {}
                )
              }
            }),
            {}
          )
          context.commit('setUnitsList', fetchedUnits)
          context.commit('setInspectionsConfig', resp.data)
          resolve()
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  fetchInspectionReport({ state, dispatch, commit }, payload) {
    return new Promise((resolve, reject) => {
      this.$axios
        .get(`/inspection-reports/${payload?.id ?? state.inspectionReport?.id}`)
        .then((resp) => {
          commit('setInspectionReport', resp.data)
          resolve()
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  updateInspectionReport(context, payload) {
    return new Promise((resolve, reject) => {
      this.$axios
        .patch(
          `/inspection-reports/${context.state.inspectionReport?.id}/`,
          payload
        )
        .then((res) => {
          context.commit('setInspectionReport', res.data)
          resolve(res.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  updateConditionAssessment(context, payload) {
    return new Promise((resolve, reject) => {
      const { unitId, subjectId, id, ...field } = payload
      this.$axios
        .patch(`/inspection-subjects/${payload.id}/`, field)
        .then((res) => {
          context.commit('setSubjectConditionAssessment', {
            unitId,
            subject: res.data
          })
          resolve()
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  updateAppliance(context, { data, type }) {
    return new Promise((resolve, reject) => {
      const { id, ...field } = data
      this.$axios
        .patch(`/inspection-subjects/${id}/`, field)
        .then((res) => {
          context.commit('setAppliance', { data: res.data, type })
          resolve()
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  uploadPhoto(context, file) {
    const formData = new FormData()
    formData.append('file_obj', file)
    return new Promise((resolve, reject) => {
      this.$axios
        .post(
          `/inspection-reports/${context.state.fetchedInspection.id}/photos/`,
          formData
        )
        .then((resp) => {
          context.commit('setPhotos', resp.data.success[0])
          resolve()
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  deletePhoto(context, payload) {
    return new Promise((resolve, reject) => {
      this.$axios
        .delete(`/inspection-photos/${payload.imageId}/`)
        .then(() => {
          context.commit('deletePhotoMutation', payload.imageId)
          resolve()
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  setUnitPhoto(context, payload) {
    return new Promise((resolve, reject) => {
      this.$axios
        .patch(`/inspection-photos/${payload.photoId}/`, {
          unit: payload.unit
        })
        .then((resp) => {
          context.commit('setUnitPhotos', resp.data)
          resolve()
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  commitContract(context, { contractId, signType }) {
    return new Promise((resolve, reject) => {
      this.$axios
        .post(
          `inspection-reports/${context.state.inspectionReport?.id}/commit/`,
          { sign_type: signType }
        )
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getContractBase64(context, id) {
    return new Promise((resolve, reject) => {
      this.$axios
        .post(`inspection-reports/${id}/sync-contract/`)
        .then((response) => {
          console.log('ER EG HER?')
          context.commit('setPdf', response.data)
          console.log('og her...')
          resolve(response.data)
        })
        .catch((error) => {
          console.log('aldrei her')
          reject(error)
        })
    })
  }
}
